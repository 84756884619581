import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
import Link from '../components/Link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.`}</p>
    <p>{`Please read the following carefully to understand our views and practices regarding your personal
data and how we will treat it.`}</p>
    <p>{`The rules on processing of personal data are set out in the General Data Protection Regulation (the “GDPR”).`}</p>
    <h2>{`1. Definitions`}</h2>
    <p><strong parentName="p">{`Data controller`}</strong>{` - A controller determines the purposes and means of processing personal data.`}</p>
    <p><strong parentName="p">{`Data processor`}</strong>{` - A processor is responsible for processing personal data on behalf of a controller.`}</p>
    <p><strong parentName="p">{`Data subject`}</strong>{` – Natural person.`}</p>
    <p><strong parentName="p">{`Categories of data: Personal data`}</strong>{` - The GDPR applies to ‘personal data’ meaning any information relating to an
identifiable person who can be directly or indirectly identified in particular by reference to an
identifier (as explained in Article 6 of GDPR).`}</p>
    <p>{`For example name, passport number, home address or
private email address. Online identifiers include IP addresses and cookies.`}</p>
    <p><strong parentName="p">{`Categories of data: Special categories personal data`}</strong></p>
    <ul>
      <li parentName="ul">{`The GDPR refers to sensitive personal data as ‘special
categories of personal data’ (as explained in Article 9 of GDPR).`}</li>
    </ul>
    <p>{`The special categories specifically include genetic data, and biometric data where processed to uniquely
identify an individual.`}</p>
    <p>{`Other examples include racial and ethnic origin, sexual orientation, health data, trade union membership,
political opinions, religious or philosophical beliefs.`}</p>
    <p><strong parentName="p">{`Processing`}</strong>{` - Means any operation or set of operations which is performed on personal data or on sets of
personal data, whether or not by automated means, such as collection, recording, organisation, structuring,
storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or
otherwise making available, alignment or combination, restriction, erasure or destruction.`}</p>
    <p><strong parentName="p">{`Third party`}</strong>{` - Means a natural or legal person, public authority, agency or body other than the data subject,
controller, processor and persons who, under the direct authority of the controller or processor, are authorised
to process personal data.`}</p>
    <h2>{`2. Who Are We?`}</h2>
    <p>{`Jacobs is registered as a data controller with the Information Commissioners Office, however for the purposes
of managing cases from Clients we are the Data Processor with the Client being the Data Controller. This
means we work to documented instructions of the Data Controller.`}</p>
    <p>{`Our contact details are:`}</p>
    <Link display='block' marginBottom='18px' href='https://www.google.com/maps?ll=53.392575,-3.022165&z=13&t=m&hl=en-US&gl=GB&mapclient=embed&q=6+Europa+Blvd+Birkenhead+CH41+4PE' mdxType="Link">
	6 Europa Boulevard
	<br />
	Birkenhead
	<br />
	CH41 4PE
    </Link>
    <p>{`For all data matters contact:`}</p>
    <Link display='block' marginBottom='18px' href='https://www.google.com/maps?ll=53.392575,-3.022165&z=13&t=m&hl=en-US&gl=GB&mapclient=embed&q=6+Europa+Blvd+Birkenhead+CH41+4PE' mdxType="Link">
	Data Protection Officer
	<br />
	6 Europa Boulevard
	<br />
	Birkenhead
	<br />
	CH41 4PE
    </Link>
    <h2>{`3. The Purpose(s) of Processing Your Personal Data`}</h2>
    <p>{`We use your personal data for the following purposes:`}</p>
    <p>{`The collection of outstanding Liability Orders, Fines, Warrants, Orders and Debts owed to our Clients in accordance with legislation, codes of conduct and best practice and resolution of any requirement deemed necessary in carrying out day to day business whilst undertaking our role.`}</p>
    <h2>{`4. The Categories of Personal Data Concerned`}</h2>
    <p>{`With reference to the categories of personal data described in the definitions section, we process the following categories of your data:`}</p>
    <ul>
      <li parentName="ul">{`Personal data`}</li>
      <li parentName="ul">{`Name`}</li>
      <li parentName="ul">{`Date of Birth`}</li>
      <li parentName="ul">{`National Insurance Numbers`}</li>
      <li parentName="ul">{`Business/Company/Employer Name`}</li>
      <li parentName="ul">{`Job Title`}</li>
      <li parentName="ul">{`Profession`}</li>
      <li parentName="ul">{`Visual and audio images of you through our use of Body Worn Video, CCTV and
audio recording equipment in accordance with CIVEA Code of Conduct mandatory
requirement.`}</li>
      <li parentName="ul">{`Contact information such as email addresses and telephone numbers.`}</li>
      <li parentName="ul">{`Demographic information such as postcode and preferences.`}</li>
      <li parentName="ul">{`Financial information such as household income (salary/benefits/assets etc.),
expenditure, credit/debit card numbers.`}</li>
      <li parentName="ul">{`Personal data collected arising out of the debt collection and enforcement process
(some of which may include sensitive data related to the customer or other third
parties), such as information on the physical or mental health or condition of the
debtor and details of incidents occurring during debt enforcement visits.`}</li>
      <li parentName="ul">{`Details of benefit entitlement and claimant details.`}</li>
      <li parentName="ul">{`IP address (automatically collected).`}</li>
      <li parentName="ul">{`Web browser type and version (automatically collected).`}</li>
      <li parentName="ul">{`Operating system (automatically collected).`}</li>
      <li parentName="ul">{`A list of URLs starting with a referring site, your activity on our site, and the site you
exit to (automatically collected).`}</li>
      <li parentName="ul">{`Caller line identification.`}</li>
      <li parentName="ul">{`Complainants and other individuals in relation to a service complaint or enquiry.`}</li>
      <li parentName="ul">{`Vehicle registration keeper details; and`}</li>
      <li parentName="ul">{`Banking details that you have permitted us to use on your behalf.`}</li>
    </ul>
    <p>{`With reference to the special categories of personal data described in the definitions section, we process the following categories of your data:`}</p>
    <ul>
      <li parentName="ul">{`Details of criminal convictions including applicable Police National Computer data
provided with relevant Warrants.`}</li>
      <li parentName="ul">{`Genetic data where processed to uniquely identify an individual.`}</li>
      <li parentName="ul">{`Racial and ethnic origin`}</li>
      <li parentName="ul">{`Health data`}</li>
    </ul>
    <p>{`We have obtained your personal data from:`}</p>
    <ul>
      <li parentName="ul">{`Clients`}</li>
      <li parentName="ul">{`Approved Trace suppliers`}</li>
      <li parentName="ul">{`Approved outsourced data suppliers`}</li>
      <li parentName="ul">{`Publicly available data via Social Media including Facebook, Twitter, Instagram etc.`}</li>
      <li parentName="ul">{`Information you have provided as part of an application form`}</li>
      <li parentName="ul">{`Information you have provided to support a means enquiry`}</li>
    </ul>
    <h2>{`5. What is Our Legal Basis for Processing Your Personal Data?`}</h2>
    <p><strong parentName="p">{`Personal data (article 6 of GDPR) lawful basis for processing your general personal data:`}</strong></p>
    <p>{`Our processing is necessary for compliance with a legal obligation and for the performance of a task carried
out in the public interest. The processing is necessary to identify persons with outstanding Liability Orders,
Fines, Warrants, Orders or Debts owed to our Clients, to obtain payment, remove goods or execute the
Warrant. Jacobs are acting on the basis of authority from the court and their power to enforce or execute
Warrants is set out in the following acts or regulations:`}</p>
    <ul>
      <li parentName="ul">{`Tribunal Courts and Enforcement Act 2007, Schedule 12`}</li>
      <li parentName="ul">{`Taking Control of Goods Regulations 2013`}</li>
      <li parentName="ul">{`Taking Control of Goods (Fees) Regulations 2014`}</li>
      <li parentName="ul">{`Magistrates’ Courts Act 1980`}</li>
      <li parentName="ul">{`Magistrates’ Courts Warrants (Specifications of Provisions) Order 2000 as amended`}</li>
      <li parentName="ul">{`Crimes and Courts Act 2013`}</li>
      <li parentName="ul">{`Domestic Violence Crime and Victim Act 2004 (S4)`}</li>
    </ul>
    <p>{`On occasions, and only where we have considered purpose, necessity and balancing tests, our processing will be for Legitimate Interests to support you or Jacobs. Where we partner with a research body/trade industry body such as the Enforcement Conduct Board (“ECB”), to assist them in carrying out research into the industry, this may involve us sharing anonymized and/or pseudonymized Body Worn footage with a research company for them to analyse from time to time.`}</p>
    <h2>{`Special categories of personal data (article 9 of GDPR) lawful basis for processing your general personal data:`}</h2>
    <p>{`Jacobs may require you to provide information to support claims of vulnerability. This is consent based data
processing where you will agree to provide the information required for Jacobs to process such claims.
Information provided shall only be used for this purpose.`}</p>
    <p>{`As an Approved Enforcement Agency for His Majesty’s Courts and Tribunals Service, we have official authority
to process Warrants and Orders which relate to criminal convictions or offences. Processing of this data is
necessary for the administration of justice.`}</p>
    <p>{`More information on lawful processing can be found on the `}<Link href='https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/' mdxType="Link">{`ICO website`}</Link>{`.`}</p>
    <h2>{`6. Sharing Your Personal Data`}</h2>
    <p>{`Your personal data will be treated as strictly confidential.`}</p>
    <p>{`We may sometimes contract with third parties to supply services to you on our behalf or provide services on
our behalf. We have a number of 3rd parties who provide specialist services on behalf of Jacobs including
processing systems, payment processing, tracing and mailing.`}</p>
    <p>{`These companies are:`}</p>
    <ul>
      <li parentName="ul">
        <Link href='https://www.civica.com/en-gb/product-pages/collections' mdxType="Link">
	Civica
        </Link>
      </li>
      <li parentName="ul">
        <Link href='https://www.adaresec.com' mdxType="Link">Adare Sec</Link>
      </li>
      <li parentName="ul">
        <Link href='https://www.opayo.co.uk' mdxType="Link">Opayo (formerly Sagepay)</Link>
      </li>
      <li parentName="ul">
        <Link href='https://www.datatraceuk.com' mdxType="Link">DataTraceUK</Link>
      </li>
      <li parentName="ul">
        <Link href='https://www.uksearchlimited.com' mdxType="Link">UKSearch ltd</Link>
      </li>
      <li parentName="ul">
        <Link href='https://www.gov.uk/government/organisations/driver-and-vehicle-licensing-agency' mdxType="Link">
	DVLA
        </Link>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Research Companies that have been appointed to view personal information (particularly Body Worn footage) to undertake research and procedure anonymized reports for the ECB (an independent oversight body for the debt collection industry which Jacobs are active in).`}</p>
        <Link href='https://enforcementconductboard.org/' mdxType="Link">
	https://enforcementconductboard.org/
        </Link>
      </li>
    </ul>
    <p>{`In some cases, the third parties may hold or require access to some or all your data for example to issue notices on our behalf, support insurance claims/disputes etc. Where any of your data is required for such a purpose, we will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under the law.`}</p>
    <p>{`We may compile statistics about the use of our site including data on traffic, usage patterns, user numbers,
sales and other information. All such data will be anonymised and will not include any personally identifying
information. We may, from time to time, share such data with third parties such as prospective investors,
affiliates, partners and advertisers. Data will only be shared and used within the bounds of the law.`}</p>
    <p>{`In certain circumstances we may be legally required to share certain data held by us, which may include your
personal information, for example, where we are involved in legal proceedings, where we are complying
with the requirements of legislation, a court order, or a governmental authority. We do not require any further
consent from you in order to share your data in such circumstances and will comply, as required, with any
legally binding request that is made of us.`}</p>
    <p>{`We may share your data in pursuing an interest that corresponds with a general public interest or a third party’s
interest. This may include situations where we are required to go beyond its specific legal obligations set in
laws and regulations to assist law enforcement or private stakeholders in their efforts to combat illegal activities,
such as money laundering, fraud prevention or misuse of services. However, the use of personal data in such
circumstances will be restricted to data which is relevant to our services and necessary to identify you.`}</p>
    <p>{`We may share Body Worn Video recordings, CCTV recordings or call recordings with the client whom we are acting for, to investigate a complaint. We may share anonymised or pseudonymized Body Worn footage with a research company acting on behalf of the ECB for them to analyse from time to time.`}</p>
    <h2>{`7. How Long Do We Keep Your Personal Data?`}</h2>
    <p>{`We keep your personal data for no longer than reasonably necessary which we deem to be a period of up to 7 years in order to assist us to contact you and possibly prevent unnecessary fees, deal with Client queries,
confirm fees and VAT queries to the relevant authorities, deal with any legal challenges which can take up to 6 years to be initiated, and for the Health and Safety of our staff. In terms of call recordings, Body Worn Video
recordings and CCTV recordings we retain these for 6 months, unless they are being used to investigate a complaint. All emails are stored for up to 90 days.`}</p>
    <h2>{`8. Providing Us With Your Personal Data`}</h2>
    <p>{`We receive sufficient information about you from our Local Authority Council Clients and His Majesty’s Courts
and Tribunal Services to allow us to enforce the Court Orders, Warrants and Debt we are instructed to collect.`}</p>
    <p>{`Where we request extra information from you such as financial circumstances, evidence of vulnerability and
contact details such information assists us in our statutory duties and assists us to follow the appropriate
Regulations. If you do not provide us with information, we request we will have to make decisions on the
information we have.`}</p>
    <h2>{`9. Your Rights and Your Personal Data`}</h2>
    <p>{`Unless subject to an exemption under the GDPR, you have the following rights with respect to your
personal data:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The right to request a copy of the personal data which we hold about you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The right to request that we correct any personal data if it is found to be inaccurate
or out of date.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The right to request your personal data is erased where it is no longer necessary to
retain such data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The right to request that we provide you with your personal data and where
possible, to transmit that data directly to another data controller, (known as the right
to data portability), (where applicable i.e. where the processing is based on consent
or is necessary for the performance of a contract with the data subject and where
the data controller processes the data by automated means).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The right, where there is a dispute in relation to the accuracy or processing of your
personal data, to request a restriction is placed on further processing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The right to object to the processing of personal data. In certain circumstances, where we only process your personal data because we have a legitimate business need to do so, you have the right to object to our processing of your personal data. Please note, where we use BWV footage for research purposes, some of your rights stated below may not apply in relation to such uses – in particular, where we rely on the research and statistics exemption under the Data Protection Act 2018, as this disapplies such rights.`}</p>
      </li>
    </ul>
    <h2>{`10. Transfer of Data Abroad`}</h2>
    <p>{`We do not transfer personal data outside the EEA.`}</p>
    <h2>{`11. Automated Decision Making`}</h2>
    <p>{`We do not use any form of automated decision making in our business with actions undertaken being
determined by rules and not of an autonomous nature`}</p>
    <h2>{`12. Further Processing`}</h2>
    <p>{`If we wish to use your personal data for a new purpose, not covered by this Data Privacy Notice,
then we will provide you with a new notice explaining this new use prior to commencing the
processing and setting out the relevant purposes and processing conditions.`}</p>
    <h2>{`13. Changes to Our Privacy Policy`}</h2>
    <p>{`Any changes we may make to our privacy policy in the future will be posted on this page and, where
appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes
to our privacy policy.`}</p>
    <h2>{`14. How to Make a Complaint`}</h2>
    <p>{`To exercise all relevant rights, queries or complaints please in the first instance contact our Data Protection
Officer, 6 Europa Boulevard, Birkenhead. CH41 4PE.`}</p>
    <p>{`If this does not resolve your complaint to your satisfaction, you have the right to lodge a complaint with the Information Commissioners Office.`}</p>
    <p>{`You can find the most up to date methods to make your complaint at `}<a parentName="p" {...{
        "href": "https://ico.org.uk/make-a-complaint/"
      }}>{`https://ico.org.uk/make-a-complaint/`}</a>{` or you
can contact them via:`}</p>
    <p>{`Telephone: 0303 123 1113,`}</p>
    <p>{`Live chat: `}<a parentName="p" {...{
        "href": "https://ico.org.uk/global/contact-us/live-chat"
      }}>{`https://ico.org.uk/global/contact-us/live-chat`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      